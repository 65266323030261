import React from "react"
import { PaginationWrapper } from "./Pagination.styles"
import PaginationItem from "./PaginationItem"

function Pagination({ prevTutorial, nextTutorial }) {
  return (
    <>
      <hr className="my-4" />
      <PaginationWrapper>
        <div className="row align-items-center">
          <div className="col-12 col-sm-6 col-md-5 h-100">
            <PaginationItem tutorial={prevTutorial} />
          </div>
          <div className="d-none d-md-block col-md-2" />
          <div className="col-12 col-sm-6 col-md-5 h-100 mt-3 mt-sm-0">
            <PaginationItem tutorial={nextTutorial} isEnd={true} />
          </div>
        </div>
      </PaginationWrapper>
    </>
  )
}

export default Pagination
