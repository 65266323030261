import React from "react"
import { ShareArticleSection } from "./ShareArticle.styles"

function ShareArticle({ title, url }) {
  const twitterLink = `https://twitter.com/intent/tweet?text=${title}&url=${url}`
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?t=${title}&u=${url}`
  const linkedInLink = `https://www.linkedin.com/sharing/share-offsite/?mini=true&title=${title}&url=${url}`

  return (
    <>
      <hr className="my-4" />
      <ShareArticleSection>
        <div>
          <h5 className="mb-0">Share this article</h5>
        </div>
        <div className="share-links">
          <a href={twitterLink} target="_blank" rel="noreferrer">
            <i className="fab fa-twitter-square" />
          </a>
          <a href={facebookLink} target="_blank" rel="noopener">
            <i className="fab fa-facebook-square" />
          </a>
          <a href={linkedInLink} target="_blank" rel="noreferrer">
            <i className="fab fa-linkedin" />
          </a>
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              navigator.clipboard.writeText(url).then(r => {})
            }}
            className="link-icon"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-link" />
          </a>
        </div>
      </ShareArticleSection>
    </>
  )
}

export default ShareArticle
