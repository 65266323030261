import styled from "styled-components"

export const ShareArticleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .share-links {
    display: flex;
    align-items: center;

    i {
      margin: 0 8px;
      font-size: 2.5rem;
    }

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      line-height: 2.5rem;
    }

    .link-icon {
      margin-left: 5px;
      background-color: var(--white-color);
      line-height: 2.2rem;
      height: 2.2rem;
      width: 2.2rem;
      padding: 2px;
      border-radius: 3px;

      .fa-link {
        font-size: 1.4rem;
        color: var(--primary-color);
      }
    }
  }
`
