import styled from "styled-components"

export const PaginationWrapper = styled.div`
  .pagination-link {
    border: 1px solid var(--white-color);
    display: flex;
    padding: 12px 20px;
    border-radius: 15px;
    align-items: center;
    height: 100%;
    
    &:hover {
      background-color: var(--white-color-10)
    }
    
    i {
      font-size: 2rem;
    }
  }
`