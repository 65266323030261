import React from "react"
import TutorialsLayout from "../../components/tutorials-layout"
import { Link } from "gatsby"
import skeletonPuzzleImage from "../../images/skeleton-puzzle.png"
import funPuzzleImage from "../../images/fun-puzzle.gif"
import dashSketch from "../../images/dash-sketch.png"
import featuresImage from "../../images/features.png"
import StoreLinks from "../../components/store-links/StoreLinks"
import Seo from "../../components/seo"
import GetTheAppSection from "../../components/get-the-app-section/GetTheAppSection"
import ShareArticle from "../../components/share-article/ShareArticle"
import Pagination from "../../components/pagination/Pagination"
import { sidebarLinks } from "../../data"

function IntroductionPage() {
  return (
    <>
      <Seo
        title="Introduction"
        titleSuffix="Dashtronaut Tutorials"
        image="https://dashtronaut.app/images/introduction-tutorial-preview.png"
      />
      <h1>Introduction</h1>
      <h4>About the app. the development story and inspiration</h4>
      <br />
      <div className="demo-video-container">
        <iframe
          className="video"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6SrPcV8I1II"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <h2>Inspiration</h2>
      <p>
        When the Flutter Puzzle Hack Hackathon was{" "}
        <a
          target="_blank"
          href="https://twitter.com/FlutterDev/status/1479184966927872000"
        >
          announced
        </a>
        , I had just got Covid and was in house quarantine. So in the midst of
        all the physical as well as psychological symptoms, most importantly,
        feeling like my life has no meaning, this challenge came to the rescue.
        It gave me the opportunity to focus all the energy I can summon to work
        on something I’m extremely passionate about. So I dove in and did
        nothing but Flutter day and night for a couple of weeks to create my
        version of the slide puzzle.
      </p>
      <p>
        I cherished the distraction and brainstormed lots and lots of ideas. I
        wanted so badly to challenge myself. I had been working with Flutter for
        about 2.5 years and I felt confident that my skills and experience are
        up to the challenge. Also, being passionate about and having some
        experience in graphic & UI design, I knew that I had the ability and
        opportunity to create something cool visually and programmatically!{" "}
        <a target="_blank" href="https://youtu.be/MIepaf7ks40?t=182">
          (Ahm, unicorn developer speaking 🦄)
        </a>
      </p>
      <h2>About Dashtronaut</h2>
      <img
        className="my-3"
        src={featuresImage}
        alt="Dashtronaut Flutter Slide Puzzle App Features"
      />
      <p>
        Dashtronaut is a slide puzzle game set out in space with Dashtronaut,
        Dash the Astronaut, floating in space and interacting with the user.
        Most of the basic puzzle functionality found in the example app is
        present in addition to some user experience enhancements, interactivity,
        and design features including:
      </p>
      <ol>
        <li>
          The ability to swipe the tiles and have them move in the direction of
          the swipe.
        </li>
        <li>
          Giving the user the option to change the puzzle size to easier or more
          difficult sizes to solve than the default 4x4 puzzle.
        </li>
        <li>
          <Link to="/tutorials/rive-tile-animations">
            Tile animations created with Rive
          </Link>{" "}
          that run when a tile is moved to its correct place and when the puzzle
          is solved.
        </li>
        <li>
          <Link to="/tutorials/local-storage">Persisting user progress</Link>{" "}
          including elapsed time, moves count and puzzle size even when the app
          is closed and reopened.
        </li>
        <li>
          <Link to="/tutorials/local-storage">
            Showing the user a list of their last 10 scores in the app drawer.
          </Link>
        </li>
        <li>
          <Link to="/tutorials/stopwatch">Stopwatch</Link>
          that starts automatically on the first tile move.
        </li>
        <li>
          Space-themed environment design around the puzzle with{" "}
          <Link to="/tutorials/background-planets">
            planets that animate into view
          </Link>{" "}
          along with the puzzle board on app launch as well as{" "}
          <Link to="/tutorials/background-stars">
            stars randomly laid out and animated using Flutter’s CustomPainter
          </Link>
        </li>
        <li>
          <Link to="/tutorials/dash-rive-animation">Dashtronaut</Link> floats
          into view on app launch then floats in place. With{" "}
          <Link to="/tutorials/phrase-bubbles">phrase bubbles</Link>, tapping on
          her let’s her express herself in a fun way. She also motivates the
          user to solve the puzzle and she is amazed when they pick a difficult
          puzzle size!
        </li>
        <li>
          <Link to="/tutorials/haptic-feedback">Haptic feedback</Link> for
          multiple events like moving a tile to its correct place, solving the
          puzzle, and tapping on Dashtronaut
        </li>
        <li>
          <Link to="/tutorials/performance">
            To optimize performance, shader captured in the Skia Shader Language
            (SkSL) was warmed up and cached and added to the builds of each
            platform to avoid animation jank on the first app run.
          </Link>
        </li>
        <li>
          <Link to="/tutorials/responsiveness">Responsiveness:</Link> The puzzle
          and its surrounding UI is responsive for all screen sizes. Most
          importantly, the puzzle board is laid out such that it is all in-view
          in landscape mode in small screens
        </li>
      </ol>
      <h2>How Dashtronaut Was Built</h2>
      <p>
        I started by reading through the{" "}
        <a href="https://github.com/VGVentures/slide_puzzle">example code</a>{" "}
        made by the awesome people of{" "}
        <a href="https://twitter.com/VGVentures">Very Good Ventures</a>. I
        learned a lot from it but ended up starting from scratch and only using
        the puzzle logic (e.g. solvable puzzle algorithm) from the example code.
        I wanted an empty canvas to spread my ideas on and I wanted to make
        tweaks on the way the puzzle tiles were laid out and how they interacted
        with user input.
      </p>
      <p>
        After doing some planning and typing up some Todo’s in a text note that
        is growing in length until this very moment, I dove into the development
        of my slide puzzle version by clearing out the code of the good old
        Flutter counter app that comes with any new Flutter project and started
        coding from there.
      </p>
      <p>
        In the couple of weeks I was quarantined and working only on my slide
        puzzle, I managed to finish only the basic puzzle logic and layout. I
        achieved what I had in mind by adding the ability to swipe the tile in
        the target position direction and have them animate to it. And I added
        keyboard and mouse support. At this point the puzzle was a skeleton! It
        looked like this:
      </p>
      <img
        src={skeletonPuzzleImage}
        alt="Dashtronaut Flutter Slide Puzzle Old State"
        style={{ width: "80% !important" }}
      />
      <br />
      <p>It even had the ability to do this:</p>
      <img
        src={funPuzzleImage}
        alt="Dashtronaut Flutter Slide Puzzle App"
        className="fun-puzzle-gif"
      />
      <br />
      <p>
        Anyway, as soon as the puzzle was in a stable state and I got to the
        design part, I tested negative for Covid and had to get back to real
        life and I wasn’t able to focus on the puzzle full-time. So in the
        remaining weeks before the deadline, in between client projects and my
        full-time job responsibilities, I squeezed in some hours and was able to
        create the designs and animations, come up with the Dashtronaut name,
        buy a domain to host the web app and the app landing page and tutorials
        pages, which I also built as quickly as I could with pure Html, CSS &
        JavaScript, deploy the web app, submit the app to the App Store (iOS &
        MacOS) and to the Google Play Store for Android, and finally, work on
        the demo video.
      </p>
      <h4>Design Inspiration - Why Dashtronaut?</h4>
      <p>
        I liked the idea of presenting the image of the tiles floating in space,
        so I created the design accordingly. And because this is a game, I
        wanted to add something fun and interactive to it, I wanted to add a
        character! And since the puzzle is in space, it made sense to have an
        astronaut character. But what made even more sense, and what I’ve wanted
        to do since the moment I saw the hackathon announcement, is bring Dash,
        the beloved Flutter & Dart mascot, into the picture!
      </p>
      <p>
        By grabbing a pen & paper and doing a quick sketch,{" "}
        <strong>Dashtronaut</strong>, Dash the Astronaut 🚀, was born!
      </p>
      <img
        src={dashSketch}
        alt="Dashtronaut Flutter Slide Puzzle Dash (Dashatar, Flutter Mascot) Sketch"
      />
      <h2>What's next for Dashtronaut?</h2>
      <p>
        With Flutter 💙, there are absolutely no limits to what you can do. And
        Dashtronaut is nothing but a small portion of what I wanted to do for
        the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://flutterhack.devpost.com/"
        >
          Flutter Puzzle Hack Challenge
        </a>
        . But I’m proud of what I accomplished and will do my best to keep
        updating the app with features in the future. Because it turned out to
        be an amazingly fun project rich with stuff to learn and teach.
      </p>
      <p>
        Keep an eye out for new app updates as well as new tutorial additions to
        this website!
      </p>
      <Pagination nextTutorial={sidebarLinks[1]} />
      <ShareArticle
        title="Read about Dashtronaut's development story, the Slide Puzzle built with Flutter for the Flutter Puzzle Hack Challenge"
        url="https://dashtronaut.app/tutorials/introduction/"
      />
      <GetTheAppSection />
    </>
  )
}

IntroductionPage.Layout = TutorialsLayout

export default IntroductionPage
