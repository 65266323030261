import React from "react"
import { Link } from "gatsby"

function PaginationItem({ tutorial, isEnd }) {
  return tutorial ? (
    <Link
      className={`pagination-link ${isEnd ? "text-end" : ""}`}
      to={tutorial.link}
    >
      {isEnd ? null : (
        <div className="me-3">
          <i className="fas fa-angle-left" />
        </div>
      )}
      <div>
        <h5 className={`${tutorial.subtitle ? '' : 'mb-0'}`}>{tutorial.title}</h5>
        <h6 className="mb-0">{tutorial.subtitle}</h6>
      </div>
      {isEnd ? (
        <div className="ms-3">
          <i className="fas fa-angle-right" />
        </div>
      ) : null}
    </Link>
  ) : null
}

export default PaginationItem
